<template>
    <div class="main-back">
        <div class="container bg-ivory">
            <welcome-portrait></welcome-portrait>
            <about-section></about-section>
            <my-experience></my-experience>
            <project-samples></project-samples>
            <contact-form></contact-form>
            
        </div>
    </div>
</template>

<script>
import WelcomePortrait from './body/WelcomePortrait.vue'
import AboutSection from './body/AboutSection.vue'
import ProjectSamples from './body/ProjectSamples.vue'
import MyExperience from './body/MyExperience.vue'
import ContactForm from './body/ContactForm.vue'
export default {
    name: 'MainBody',
    components: {
    WelcomePortrait,
    AboutSection,
    ProjectSamples,
    MyExperience,
    ContactForm,
    }
}
</script>