<template>
  <div class="row text-center bg-ivory">
    <div class="col-sm-6 mb-auto p-4">
      <img
        id="portrait"
        src="../../images/jakeportrait.jpg"
        alt="Portrait of Jake Miller with black background"
        class="img-fluid rounded box-shadow-3d"
      />
    </div>
    <div class="col-sm-6 pl-0 mt-auto mb-auto ">
      <h3 class="txt-neon-green husband fw-bold">Welcome!</h3>
        <p class="txt-blue husband fw-bolder">
          Hi! I'm Jake Miller, studying software development at CWI. Welcome
          to my portfolio!
        </p>        
      <button type="button" href="#contact" class="btn btn-dark btnglow border border-light bg-aurora buttonglow mb-3">Contact Me</button>
    </div>
    
  <hr />
  <h3><a href="#projects" class="txt-neon-green">Projects Preview</a></h3>
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>    
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
  </div>
  <div class="carousel-inner box-shadow-3d">
    <div class="carousel-item active">
        <a href="https://main.d3qs7hrb7ege5j.amplifyapp.com/" target="_blank">
          <img src="../../images/cookiepreview.jpg" class="d-block w-100 img-fluid rounded " alt="Preview of best chocolate chip cookies web site">        
      <div class="carousel-caption carousel-highlight d-none d-sm-block">   
        <h5>Best Cookies</h5>
        <p>Built with Vue3 & Bootstrap5</p>      
      </div>
    </a>
    </div>
    <div class="carousel-item">
        <a href="https://main.d839l79903n1n.amplifyapp.com/" target="_blank">
          <img src="../../images/hotspringpreview.jpg" class="d-block w-100 img-fluid rounded " alt="Preview of hotsprings web site">
      <div class="carousel-caption carousel-highlight d-none d-sm-block">
        <h5>HotSprings</h5>
        <p>Built with Angular & Bootstrap5</p>
      </div>      
    </a>
    </div>
    <div class="carousel-item">
        <a href="https://hcu6fk.csb.app/" target="_blank">
          <img src="../../images/bggpreview.jpg" class="d-block w-100 img-fluid rounded " alt="Preview of boardgame gathering site">
      <div class="carousel-caption carousel-highlight d-none d-sm-block">
        <h5>Board Game Gathering</h5>
        <p>Built with Vue3 & Bootstrap5</p>
      </div>      
    </a>
    </div>    
    <div class="carousel-item">
        <a href="https://tu9fef.csb.app/" target="_blank">
          <img src="../../images/spiderwebpreview.jpg" class="d-block w-100 img-fluid rounded " alt="Preview of spiderweb web site">
      <div class="carousel-caption carousel-highlight d-none d-sm-block">
        <h5>SpiderWeb</h5>
        <p>Built with React & Bootstrap5</p>
      </div>
      </a>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
    </div>
</div>
</template>

<script>
export default {
  name: 'WelcomePortrait',
}
</script>
<style>

.carousel-highlight {
  background-color: rgba(128, 128, 128, 0.476);  
  text-shadow: 0px 0px 10px black;
  border: 2px outset #1dc690 !important;
  box-shadow: 0px 0px 10px #5e0189;
}

.carousel-highlight:focus,
.carousel-highlight:hover,
.carousel-highlight:active {
  color: white;
  text-shadow: 0px 0px 5px #1dc690 !important;
}
</style>