<template>
    <nav class="navbar navbar-expand-sm sticky-top bg-blue navbar-dark aurora-trans side-trans-bot side-trans-top">
  <div class="container-fluid">
    <a class="navbar-brand" href="#home"><img class="rounded img-fluid" width="60px" src="../images/JMicon.svg" alt="Jake Miller's Icon"></a>
    <button class="navbar-toggler bg-aurora" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon "></span>
    </button>
    <div class="collapse navbar-collapse text-center justify-content-end" id="navbarNavAltMarkup">
      <div class="navbar-nav text-center">
        <a class="nav-link" href="#home">Home</a>
        <a class="nav-link" href="#about">About</a>
        <a class="nav-link" href="#experience">Experience</a>
        <a class="nav-link" href="#projects">Projects</a>
        <a class="nav-link" href="#contact">Contact</a>
      </div>
    </div>
  </div>
</nav>
</template>
<script>
export default {
  name: 'NavBar',
  props: {
    msg: String
  }
}
</script>