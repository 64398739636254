<template>
    <div class="row text-center side-trans-top py-4">
        <h2 class="text-center txt-neon-green py-2">Project Samples</h2>
        <div class="row text-center" id="projects">
            
          <div class="col-sm-6 mb-3 mx-auto">
            <a href="https://main.d839l79903n1n.amplifyapp.com/" target="_blank"
              ><p><small>Built with Angular and Bootstrap 5</small></p><img
                src="../../images/hotsprings.jpg"
                alt="Hot Springs site preview"
                class="img-fluid rounded submit_btn box-shadow-3d"
            /></a>
          </div>          
          <div class="col-sm-6 mb-3 mx-auto">
            <a href="https://main.d2bwx4y2b3kyjr.amplifyapp.com/" target="_blank"
              ><p><small>Built with jQuery and Bootstrap 4</small></p>
              <img
                src="../../images/kingscafe.jpg"
                alt="King's Cafe preview"
                class="img-fluid rounded submit_btn box-shadow-3d"
            /></a>
          </div>
          <div class="col-sm-6 mb-3 mx-auto">
            <a href="https://main.d3qs7hrb7ege5j.amplifyapp.com/" target="_blank"
              ><p><small>Built with Vue 3 and Bootstrap 5</small></p><img
                src="../../images/bestcookies.jpg"
                alt="Best Chocolate Chip Cookies preview"
                class="img-fluid rounded submit_btn box-shadow-3d"
            /></a>
          </div>          
          <div class="col-sm-6 mb-3 mx-auto">
            <a
              href="https://tu9fef.csb.app/"
              target="_blank"
              ><p><small>Built with React and Bootstrap 5</small></p><img
                src="../../images/SpiderWeb.jpg"
                alt="SpiderWeb preview of site that is a guide to Idaho's Spiders"
                class="img-fluid rounded submit_btn box-shadow-3d"
            /></a>
          </div>
        
          <div class="col-sm-6 mb-3 mx-auto">
            <a
              href="https://hcu6fk.csb.app/"
              target="_blank"
              ><p><small>Built with Vue 3 and Bootstrap 5</small></p><img
                src="../../images/BGG.jpg"
                alt="Board Game Gathering discussion board preview"
                class="img-fluid rounded submit_btn box-shadow-3d"
            /></a>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectSamples',
}
</script>