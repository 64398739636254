<template>
  <jumbo-gif></jumbo-gif>
  <nav-bar></nav-bar>
  <main-body></main-body>
  <bot-footer></bot-footer>
</template>

<script>
import JumboGif from './components/JumboGif.vue'
import NavBar from './components/NavBar.vue'
import MainBody from './components/MainBody.vue'
import BotFooter from './components/BotFooter.vue'

export default {
  name: 'App',
  components: {
    JumboGif,
    NavBar,
    MainBody,
    BotFooter,
  }
}
</script>

<style>
/* Electrolize font */
@import url("https://fonts.googleapis.com/css2?family=Electrolize&display=swap");
/*Icon Fonts */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

@font-face {
  font-family: "husbandofthemillenium";
  src: url(./fonts/husbandofthemillennium.woff),
    url(./fonts/husbandofthemillennium.woff2);
}

.husband{
  font-family: "husbandofthemillenium";
}
/* Background Color Schemes */
.thisblue {
  background-color: #278ab0;
}
.bg-blue {
  background-color: #1c4670;
}
.txt-blue {
  color: #1c4670;
}
.bg-ivory {
  background-color: #eaeae0;
}
.bg-neon-green {
  background-color: #1dc690;
}
.txt-neon-green {
  color: #1dc690;
}
.bg-purple {
  background-color: #5e0189fc;
}
.txt-purple {
  color: #5e0189fc;
}
 
.buttonglow:hover, 
.buttonglow:active, 
.buttonglow:focus {  
  border: 2px outset #1dc690 !important;
  box-shadow: 0px 0px 10px #5e0189;
}

.bg-aurora {
  background: linear-gradient(to bottom left, #1dc690, #5e0189);
}

.box-shadow-3d {
  box-shadow: 1px 1px 0px #999, 2px 2px 0px #999, 3px 3px 0px #999,
    4px 4px 0px #999, 5px 5px 0px #999, 6px 6px 0px #999;
}

.main-back {  
  background-image: radial-gradient(#eaeaea78, #2789b073);
  animation: nav-trans 15s infinite, aurora-border 30s infinite;
}

.side-trans-top {
  border-top-style: solid;
  border-color: #1c4670;
  border-width: 7px 0px;
}

.side-trans-bot {
  border-bottom-style: solid;
  border-color: #1c4670;
  border-width: 7px 0px;
}

.aurora-trans {
  animation: nav-trans 30s infinite;
}

.bg-aurora-grad {
  animation: nav-trans 30s -15s infinite both;
  background-image: radial-gradient(#5e018952, #278ab0);
}

/* Keyframes */
@keyframes nav-trans {
  0% {
    background-color: #1c4670;
  }
  33% {
    background-color: #420060;
  }
  66% {
    background-color: #005457;
  }
  100% {
    background-color: #1c4670;
  }

  /* 0%{background: linear-gradient(to bottom right, #1DC690, #5e0189)}
    50%{background: linear-gradient(to bottom left, #5e0189, #1C4670)}
    100%{background: linear-gradient(to bottom right, #1DC690, #5e0189)} */
}
@keyframes aurora-border {
  0% {
    border-color: #005457;
  }
  33% {
    border-color: #1c4670;
  }
  66% {
    border-color: #420060;
  }
  100% {
    border-color: #005457;
  }
}

</style>
