<template>
    <div class="text-center bg-aurora-grad" id="home">
        <div class="container-fluid">
            <img class="img-fluid rounded p-2" src="../images/aurora.gif" alt="gif of northern lights over mountains and a reflecting lake">
        </div>
    </div>
</template>
<script>

export default {
  name: 'JumboGif',
  props: {
    msg: String
  }
}
</script>

<style>
img.aurora {
    max-height: 150px;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0px 0px 25px #5e0189fc;
    border-image: linear-gradient(to bottom right, #1dc690, #5e0189);
  }</style>