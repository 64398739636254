<template>    
    <div class="row text-center bg-ivory pt-5" id="about">
          <div class="col-xl-6 mb-auto p-4 side-trans-top">
            <h1 class="txt-neon-green husband fw-bold">Jake Miller</h1>
            <img
              id="rachelnjake"
              src="../../images/rachelnjake.jpg"
              class="img-fluid rounded box-shadow-3d"
              alt="Rachel and Jake on a with light shining and grape vines in the back"
            />
          </div>
          <div class="col-xl-6 mt-auto mb-auto p-4">
            <p class="long">
              Hi, I'm <span class="txt-purple">Jake Miller</span>, a software developer and an Idaho
              native. I have a wonderful
              wife named Rachel, and I am currently studying software development as both a passion and way to
              provide for our young family. I've studied at both Boise State
              University and College of Western Idaho.
            </p>
            <p class="long">
              I love the outdoors. I have worked in a wide variety of settings,
              learning diverse skills which uniquely equip me. I have several
              hobbies including playing multiple instruments, backpacking,
              snowboarding, biking, and playing board games with my wife.
            </p>
          </div>
        </div>
</template>
<script>
export default {

}</script>