<template>    
    <div class="row text-center bg-ivory side-trans-top pt-4" id="contact">
          <div class="col mt-2">
            <h1><span class="txt-neon-green">Questions? Comments?</span></h1>
          </div>
        </div>

        <!-- Form, using both "Input-Group" and "Forms" Bootstrap Components-->
        <h3 class="mt-3 text-center">Please fill out the form below</h3>
        <form
          action="mailto:JakeMiller@my.cwi.edu?subject=Portfolio"
          enctype="text/plain"
          method="POST"
        >
          <div class="form-row mt-4">
            <div class="col input-group">
              <div class="input-group-prepend  ">
                <span class="input-group-text">First and Last Name</span>
              </div>
              <input
                name="FirstName"
                type="text"
                class="form-control buttonglow"
                placeholder="First"
                id="inputFirstName"
              />
              <input
                name="LastName"
                type="text"
                class="form-control buttonglow"
                placeholder="Last"
                id="inputLastName"
              />
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="input-group col">
              <div class="input-group-prepend  ">
                <span class="input-group-text">Email</span>
              </div>
              <input
                name="email"
                type="email"
                class="form-control buttonglow"
                id="inputEmail"
              />
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="input-group col">
              <div class="input-group-prepend  ">
                <span class="input-group-text">Phone</span>
              </div>
              <input
                name="phone"
                type="tel"
                class="form-control buttonglow"
                id="inputPhone"
              />
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="input-group col">
              <label for="inputTextArea">Comments/Questions</label>
              <textarea
                name="comments"
                class="form-text w-100 buttonglow"
                id="inputTextArea"
              ></textarea>
            </div>
          </div>
          <div class="text-center py-3">
          <button
            type="submit"
            class="btn btn-dark btnglow border border-light bg-aurora buttonglow mb-3"
            value="Submit"
          >
            Submit
          </button></div>
        </form>
</template>

<script>
export default {

}
</script>