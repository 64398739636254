<template>
    <div class="row bg-ivory side-trans-top text-center" id="experience">
        <!-- 
        <doughnut
        :options="doughnutOptions"
        :data="doughnutData"></doughnut>-->
        
        <h1 class="txt-neon-green">Programming Experience</h1>
        <p><small>(Last Update 4/4/2023: Updated Experience Content Coming Soon)</small></p>
        <div class="text-left mx-auto row">
          <div class="col-1"></div>
          <div class="col text-left border border-dark rounded mb-3">
        <section>
          <h2>Technologies Learned</h2>
          <ul>
            <li>HTML 5</li>
            <li>CSS</li>
            <li>JavaScript</li>
            <li>Vue 2 and 3</li>
            <li>React</li>
            <li>Angular</li>
            <li>C#</li>
            <li>SQL Server</li>
          </ul>
        </section>
        <hr>
        <section> 
          <h2>Technologies I'm currently learning</h2>
          <ul>            
            <li>PHP</li>
            <li>ASP.NET</li>
            <li>MySQL</li>
          </ul>
        </section>
        <hr>
        <section> 
          <h2>Certifications</h2>
          <ul>            
            <li><a href="https://www.credly.com/badges/64f7fec4-e36e-435f-ba8b-da784223d8d9/linked_in_profile">
              CERTIPORT IT Specialist - Databases</a></li>        
          </ul>
        </section>
        <hr>
        <section>
          <h2>Additional Experience</h2>
          <p>I have worked in a variety of settings including:</p>
          <ul>
            <li>
              Finisher at the Blower Shop (machine shop for superchargers)
            </li>
            <li>Pizza delivery expert</li>
            <li>Sale representative at Vector Marketing</li>
            <li>Musician in a variety of local jazz groups</li>
            <li>Air duct/carpet cleaning/water damage technician</li>
            <li>Swim instructor/lifegaurd</li>
          </ul>
        </section>   
      </div>     
        <div class="col-1"></div>
      </div>
    </div> 
</template>


<script>
// import { Doughnut } from 'vue-chartjs'
export default {
    components: { //Doughnut 
    },
    /*data() {
        return {
            doughnutData: {
                labels: [ 'HTML', 'CSS', 'JavaScript'],
                datasets: [
                    {
                        data: [40, 30, 30],
                        backgroundColor: [
                            '#1dc690',
                            '#5e0189fc',
                            '#278ab0'
                        ]
                    }
                ]
            },
            doughnutOptions: {
                responsive: true
            }
        }
    } */
} 
</script>